<template>
  <div class="applied-medical-payment-list-table">
    <div class="table-header">
      <div class="cell select">
        <base-check-box
          :isChecked="allCheckFlg"
          @input="value => $emit('toggle-all-check-flg', value)"
        />
      </div>
      <div class="cell">診療日</div>
      <div class="cell owner">
        <div>飼主ID</div>
        <div>飼主名</div>
      </div>
      <div class="cell patient">
        <div>患者ID</div>
        <div>患者名</div>
      </div>
      <div class="cell jp-insurance">保険種類</div>
      <div class="cell pledge-rate">保険割合</div>
      <div class="cell">診療費</div>
      <div class="cell insurance-price">保険金請求額</div>
    </div>
    <recycle-scroller
      v-if="data.length > 0"
      class="scroller"
      :items="data"
      :item-size="60"
      key-field="id"
      v-slot="{ item, index }"
    >
      <applied-medical-payment-list-table-row
        v-bind="item"
        :index="index"
        @input="obj => $emit('input', obj)"
      />
    </recycle-scroller>
    <div v-else>対象のデータはありませんでした</div>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import AppliedMedicalPaymentListTableRow from '@/components/parts/molecules/AppliedMedicalPaymentListTableRow'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  name: 'AppliedMedicalPaymentListTable',

  components: {
    BaseCheckBox,
    AppliedMedicalPaymentListTableRow,
    RecycleScroller
  },

  props: {
    data: { type: Array },
    allCheckFlg: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.applied-medical-payment-list-table {
  > .table-header {
    font-size: 15px;
    box-sizing: border-box;
    display: flex;
    height: 56px;
    background-color: #{$pale};
    > .cell {
      border: 1px solid #{$light-grey};
      border-right: none;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      &.select {
        max-width: 50px;
      }
      &.jp-insurance {
        max-width: 130px;
      }
      &.pledge-rate {
        max-width: 130px;
      }
      &.insurance-price {
        border-right: 1px solid #{$light-grey};
      }
    }
    > .cell ~ .cell {
      border-left: 1px solid #{$skin};
    }
  }
  > .scroller {
    max-height: 670px;
    border-bottom: 1px solid #{$light-grey};
  }
}
</style>
