<template>
  <div
    class="anicom-report-table-row"
    :class="{ even: (index + 1) % 2 === 0 }"
    ref="target"
  >
    <div class="check" v-if="tab === 'unsent'">
      <base-check-box
        class="check-box"
        :isChecked="isChecked"
        @input="toggleSingleChecked"
        :disabled="!(isSend === '可' || isSend === 'キャンセル可')"
      />
    </div>
    <div class="cells" @click="handleClick" data-test="cell">
      <div
        class="cell"
        :class="{ border: tab === 'sent' }"
        data-test="rezept-cd-date"
      >
        {{ rezeptCdDate }}
      </div>
      <div class="cell" data-test="anicom-c-id">{{ anicomCId }}</div>
      <div class="cell" data-test="owner-last-name" :title="ownerLastName">
        <div class="text">{{ ownerLastName }}</div>
      </div>
      <div class="cell" data-test="patient-name" :title="patientName">
        <div class="text">{{ patientName }}</div>
      </div>
      <div class="cell" data-test="medical-expenses">{{ medicalExpenses }}</div>
      <div class="cell" data-test="payment-status">{{ paymentStatus }}</div>
      <div
        class="cell is-send"
        data-test="is-send"
        :class="{ red: isSend === '不可(診療明細変更済み)' }"
      >
        {{ isSend }}
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'

export default {
  name: 'AnicomReportTableRow',

  components: {
    BaseCheckBox
  },

  props: {
    rezeptCdDate: { type: String, default: '' },
    id: { type: Number, default: 0 },
    anicomCId: { type: String, default: '' },
    ownerLastName: { type: String, default: '' },
    patientName: { type: String, default: '' },
    medicalExpenses: { type: String, default: '' },
    paymentStatus: { type: String, default: '' },
    isSend: { type: String, default: '' },
    isChecked: { type: Boolean, default: false },
    index: { type: Number, default: 0 },
    lastIndex: { type: Number, default: 0 },
    tab: { type: String, default: '' }
  },

  mounted() {
    const target = this.$refs.target
    let observer = new IntersectionObserver(() => {
      this.emitScrollBottom()
    })
    observer.observe(target)
  },

  methods: {
    handleClick() {
      const medicalPayment = this.$store.getters['medicalPayments/getDataById'](
        this.id
      )
      const anicomReports =
        this.$store.getters['anicomReports/getDataByMedicalPaymentId'](
          this.id
        ) ?? []
      const sentAnicomReports = anicomReports.filter(
        v => v.delFlg === 0 && v.cancelFlg === 0
      )
      if (medicalPayment.delFlg === 1 && sentAnicomReports.length === 0) {
        this.$store.dispatch('petorelu/showGlobalPopup', {
          type: 'failure',
          title: '失敗',
          buttons: ['閉じる'],
          message:
            'クリックしたレセプトの診療明細が削除されている為、\nレセプト詳細画面に移動できません。',
          close: () => this.$store.dispatch('petorelu/hideGlobalPopup')
        })
        this.$emit('clear', this.id)
      } else {
        this.$router.push({
          name: 'anicom-report-show',
          params: { medicalPaymentId: this.id }
        })
      }
    },
    toggleSingleChecked(value) {
      this.$emit('input', { value, index: this.index, id: this.id })
    },
    emitScrollBottom() {
      // スクロールバーが出てから下記のemitを発生させる
      if (this.index === this.lastIndex && this.lastIndex >= 12) {
        // this.lastIndex >= 12 → データが13件以上の場合trueになり、下記のemitをして次のデータを取得
        this.$emit('scroll-bottom')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.anicom-report-table-row {
  font-size: 13px;
  height: 60px;
  display: flex;
  @include hover();
  &.even {
    background-color: #{$white_f7};
  }
  > .check {
    border-left: 1px solid #{$light-grey};
    width: 50px;
    > .check-box {
      margin-top: 20px;
      margin-left: 15px;
    }
  }

  > .cells {
    flex: 1;
    display: flex;
    > .border {
      border-left: 1px solid #{$light-grey};
    }
    > .cell {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-align: center;
      &.select {
        max-width: 50px;
        border-left: 1px solid #{$light-grey};
      }
      &.is-send {
        border-right: 1px solid #{$light-grey};
      }
      &.red {
        color: #{$tomato};
      }
      > .text {
        flex: 1;
        width: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
