<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="print-options-popup-for-medical-payments"
      @keyup.esc.stop="close"
      tabindex="-1"
    >
      <popup
        :contentStyle="{}"
        :buttons="['閉じる', '印刷']"
        @click-close-mark="close"
        @cancel="close"
        @decision="makePdf"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title">診療明細書一括印刷</div>
            <div class="form">
              <div class="form-item" data-test="paper-size">
                <div class="label">用紙サイズ</div>
                <div class="radio-buttons">
                  <base-radio-button
                    v-for="datum in paperSizeRadioButtonData"
                    :key="'paper-size' + datum.id"
                    :option="datum"
                    v-model="printOptions.paperSize"
                  />
                </div>
              </div>
              <div
                class="form-item"
                data-test="invoice-number"
                v-if="showFormItemInvoiceNumber"
              >
                <div class="label">登録番号</div>
                <div class="radio-buttons">
                  <base-radio-button
                    v-for="datum in invoiceNumberData"
                    :key="'invoice-number' + datum.id"
                    :option="datum"
                    v-model="printOptions.showInvoiceNumber"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import { printMedicalPayment } from '@/utils/print_medical_payment'
import { mapGetters } from 'vuex'
import { FocusTrap } from 'focus-trap-vue'
import moment from 'moment'

export default {
  name: 'printOptionsPopupForMedicalPayments',

  components: {
    Popup,
    BaseRadioButton,
    FocusTrap
  },

  props: {
    checkedMedicalPayments: { type: Array, required: true },
    hospitalizations: { type: Array, required: true },
    medicalRecords: { type: Array, required: true },
    medicalTreatmentItems: { type: Array, required: true },
    medicalPayments: { type: Array, required: true }
  },

  data() {
    return {
      printOptions: {},
      showFormItemInvoiceNumber: false,
      paperSizeRadioButtonData: [
        { id: -1, eachValue: 'A4', labelName: 'A4' },
        { id: -2, eachValue: 'B5', labelName: 'B5' },
        { id: -3, eachValue: 'A5', labelName: 'A5' }
      ],
      invoiceNumberData: [
        { id: -4, eachValue: true, labelName: '表示する' },
        { id: -5, eachValue: false, labelName: '表示しない' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      clinic: 'clinic/getData',
      getDisease: 'diseases/getDataById',
      getMasterDatum: 'master/getDataById',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getMedicine: 'medicines/getDataById',
      getMedicineById: 'medicines/getDataById',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getStaffById: 'staffs/getDataById',
      getTreatmentById: 'treatments/getDataById',
      printSetting: 'printSetting/getData'
    })
  },

  created() {
    const showInvoiceNumber = Boolean(this.clinic.invoiceNumber)
    if (showInvoiceNumber) this.showFormItemInvoiceNumber = true
    this.printOptions = {
      paperSize: this.printSetting.defaultPaperSize,
      showClinicName: true,
      showInvoiceNumber,
      showBarcode: false
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },
    makeTreatmentItemData(medicalPayment, medicalTreatmentItems) {
      return medicalTreatmentItems
        .flatMap(v => {
          if (v.medicalPaymentId !== medicalPayment.id) return []
          const name =
            v.treatmentId !== 0
              ? this.getTreatmentById(v.treatmentId).name
              : v.medicineId !== 0
              ? this.getMedicineById(v.medicineId).name
              : ''
          return { ...v, name }
        })
        .sort((a, b) => a.order - b.order)
    },
    makeTreatmentDate(medicalRecord) {
      const timeFormat = HHmm => moment(HHmm, 'HHmm').format('H:mm')
      return `${this.dateFormat(medicalRecord.date, true)}${timeFormat(
        medicalRecord.startTime
      )}~${timeFormat(medicalRecord.endTime)}`
    },
    dateFormat(dateString, weekdayFlg = false) {
      return moment(dateString, 'YYYYMMDD').format(
        'Y年M月D日' + (weekdayFlg ? '（dd）' : '')
      )
    },
    makePdf() {
      const checkedMedicalPayments = this.checkedMedicalPayments
      const hospitalizations = this.hospitalizations
      const medicalTreatmentItems = this.medicalTreatmentItems
      const hospitalizationMedicalPayments = this.medicalPayments
      const medicalRecords = this.medicalRecords

      const prefectureName =
        this.getMasterDatum('prefectures', this.clinic.prefectureId)?.name || ''
      const clinic = { ...this.clinic, prefectureName }
      const clinicImage = this.printSetting.image
      // PDF処理に必要な印刷内容一覧の作成
      const printData = checkedMedicalPayments.map(medicalPayment => {
        const medicalRecord = medicalRecords.find(
          r => r.originalId === medicalPayment.medicalRecordOriginalId
        )
        const hospitalization = hospitalizations.find(
          h => h.endMedicalPaymentOriginalId === medicalPayment.originalId
        )
        medicalPayment.date = this.dateFormat(medicalRecord.date)
        if (hospitalization) {
          medicalPayment.hospitalization = hospitalization
          medicalPayment.hospitalizationMedicalPayments = hospitalization.medicalPaymentOriginalIds
            .map(originalId => {
              const hospitalizationMedicalPayment = hospitalizationMedicalPayments.find(
                h => h.originalId === originalId
              )
              const medicalRecord = medicalRecords.find(
                r =>
                  r.originalId ===
                  hospitalizationMedicalPayment.medicalRecordOriginalId
              )
              const dateTime = medicalRecord.date + medicalRecord.startTime
              return { ...hospitalizationMedicalPayment, dateTime }
            })
            .sort((a, b) => a.dateTime - b.dateTime)
          medicalPayment.treatmentItemsAndTreatmentDates = medicalPayment.hospitalizationMedicalPayments.flatMap(
            medicalPayment => {
              const treatmentItems = this.makeTreatmentItemData(
                medicalPayment,
                medicalTreatmentItems
              )
              if (!(treatmentItems.length > 0)) return []
              const medicalRecord = medicalRecords.find(
                r => r.originalId === medicalPayment.medicalRecordOriginalId
              )
              const treatmentDate = this.makeTreatmentDate(medicalRecord)
              return [{ treatmentDate }, ...treatmentItems]
            }
          )
          medicalPayment.treatmentItems = medicalPayment.hospitalizationMedicalPayments.flatMap(
            medicalPayment =>
              this.makeTreatmentItemData(medicalPayment, medicalTreatmentItems)
          )
        } else {
          medicalPayment.treatmentItems = this.makeTreatmentItemData(
            medicalPayment,
            medicalTreatmentItems
          )
        }
        medicalPayment.onsetDate = medicalPayment.uncertainOnsetFlg
          ? '不明'
          : this.dateFormat(medicalPayment.onsetDate)
        const { disease1Id, disease2Id } = medicalPayment
        medicalPayment.diseaseName = [disease1Id, disease2Id]
          .flatMap(v => this.getDisease(v)?.name ?? [])
          .join(', ')
        const staff = this.getStaffById(medicalPayment.staffId)
        medicalPayment.staffName = staff
          ? staff.lastName + ' ' + staff.firstName
          : ''
        const patient = this.getPatient(medicalPayment.patientId)
        const owner = this.getOwner(patient.ownerId)
        return { owner, patient, medicalPayment }
      })
      // 一括印刷では バーコード と 次回予約 は非表示
      printMedicalPayment({
        printOptions: this.printOptions,
        clinic,
        clinicImage,
        printData
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.print-options-popup-for-medical-payments {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    min-width: 460px;
    font-size: 15px;
    text-align: left;
    > .title {
      font-size: 16px;
      font-weight: bold;
    }
    > .form {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      > .form-item {
        display: flex;
        align-items: center;
        height: 33px;
        > .label {
          width: 160px;
        }
        > .radio-buttons {
          display: flex;
        }
      }
    }
  }
}
</style>
