<template>
  <div
    class="applied-medical-payment-list-table-row"
    :class="{ even: (index + 1) % 2 === 0 }"
  >
    <div class="cells" data-test="cells">
      <div class="cell check">
        <base-check-box
          class="check-box"
          :isChecked="isChecked"
          @input="toggleSingleChecked"
        />
      </div>
      <div class="cell" data-test="format-date">
        {{ formatDate }}
      </div>
      <div
        class="cell owner"
        data-test="owner"
        :title="`${ownerShowId}\n${ownerFullName}`"
      >
        <div class="text" data-test="owner-show-id">
          {{ ownerShowId }}
        </div>
        <div class="text" data-test="owner-full-name">
          {{ ownerFullName }}
        </div>
      </div>
      <div
        class="cell patient"
        data-test="cell"
        :title="`${patientShowId}\n${patientNameSpecies}`"
      >
        <div class="text" data-test="patient-show-id">
          {{ patientShowId }}
        </div>
        <div class="text" data-test="patient-name-species">
          {{ patientNameSpecies }}
        </div>
      </div>
      <div class="cell jp-insurance" data-test="jp-insurance">
        {{ jpInsurance }}
      </div>
      <div class="cell pledge-rate" data-test="pledge-rate">
        {{ displayPledgeRate }}
      </div>
      <div class="cell " data-test="tax-included-price">
        {{ displayTaxIncludedPrice }}
      </div>
      <div class="cell insurance-price" data-test="insurance-price">
        {{ displayInsurancePrice }}
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'

export default {
  name: 'AppliedMedicalPaymentListTableRow',

  components: {
    BaseCheckBox
  },

  props: {
    isChecked: { type: Boolean, default: false },
    formatDate: { type: String, default: '' },
    ownerShowId: { type: String, default: '' },
    ownerFullName: { type: String, default: '' },
    patientShowId: { type: String, default: '' },
    patientName: { type: String, default: '' },
    speciesName: { type: String, default: '' },
    jpInsurance: { type: String, default: '' },
    displayPledgeRate: { type: String, default: '' },
    displayTaxIncludedPrice: { type: String, default: '' },
    displayInsurancePrice: { type: String, default: '' },
    index: { type: Number, default: 0 }
  },

  computed: {
    patientNameSpecies() {
      return this.speciesName
        ? `${this.patientName} （${this.speciesName}）`
        : `${this.patientName}`
    }
  },

  methods: {
    toggleSingleChecked(value) {
      this.$emit('input', { value, index: this.index })
    }
  }
}
</script>

<style lang="scss" scoped>
.applied-medical-payment-list-table-row {
  font-size: 13px;
  height: 60px;
  display: flex;
  &.even {
    background-color: #{$white_f7};
  }
  > .cells {
    flex: 1;
    display: flex;
    > .cell {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      min-width: 0;
      padding: 0 10px;
      text-align: center;
      font-feature-settings: 'palt';
      &.check {
        max-width: 50px;
        border-left: 1px solid #{$light-grey};
      }
      &.jp-insurance {
        max-width: 130px;
      }
      &.pledge-rate {
        max-width: 130px;
      }
      &.insurance-price {
        border-right: 1px solid #{$light-grey};
      }
      > .text {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
