export const makeHospitalizationDatum = (medicalPayment, medicalRecord) => {
  return {
    patientId: medicalPayment.patientId,
    medicalPaymentOriginalId: medicalPayment.originalId,
    medicalRecordOriginalId: medicalPayment.medicalRecordOriginalId,
    date: medicalRecord.date,
    startTime: medicalRecord.startTime,
    endTime: medicalRecord.endTime,
    startHospitalizationFlg: medicalPayment.startHospitalizationFlg,
    inHospitalFlg: medicalPayment.inHospitalFlg,
    endHospitalizationFlg: medicalPayment.endHospitalizationFlg,
    insuranceType: medicalPayment.insuranceType
  }
}

/*
makeHospitalizationHistory

INPUT: hospitalizationData = [Object, ...]

Object = {
  patientId 
  medicalPaymentOriginalId
  medicalRecordOriginalId
  date ('YYYYMMDD')
  startTime ('HHmm')
  endTime ('HHmm')
  startHospitalizationFlg
  inHospitalFlg
  endHospitalizationFlg
}

OUTPUT: intervals = [Object, ...]

Object = {
  startDate ('YYYYMMDD' or 'invalid')
  startDateTime ('YYYYMMDDHHmm' or 'invalid')
  searchInterval (['YYYYMMDD', 'YYYYMMDD'])
  endDate ('YYYYMMDD' or 'invalid' or '')
  endDateTime ('YYYYMMDDHHmm' or 'invalid' or '')
  records (
    [{originalId, medicalPaymentOriginalId, startFlg, inFlg, endFlg}, ...]
    )
}
*/

export const makeHospitalizationHistory = hospitalizationData => {
  let intervals = []
  let startDate, startDateTime, searchInterval, records
  const resetValues = () => {
    startDate = ''
    startDateTime = ''
    searchInterval = ['', '']
    records = []
  }
  resetValues()
  hospitalizationData
    .sort((a, b) => {
      if (a.date !== b.date) {
        return a.date - b.date
      } else {
        if (a.startTime !== b.startTime) {
          return a.startTime < b.startTime ? -1 : 1
        } else if (a.startHospitalizationFlg) {
          return -1
        } else if (b.startHospitalizationFlg) {
          return 1
        } else if (a.endHospitalizationFlg) {
          return 1
        } else if (b.endHospitalizationFlg) {
          return -1
        } else {
          return a.originalId - b.originalId
        }
      }
    })
    .forEach(v => {
      const record = {
        originalId: v.medicalRecordOriginalId,
        medicalPaymentOriginalId: v.medicalPaymentOriginalId,
        startFlg: v.startHospitalizationFlg,
        inFlg: v.inHospitalFlg,
        endFlg: v.endHospitalizationFlg
      }
      if (!startDate) {
        if (v.startHospitalizationFlg || v.endHospitalizationFlg) {
          if (v.startHospitalizationFlg) {
            startDate = v.date
            startDateTime = v.date + v.startTime
            searchInterval = [v.date, v.date]
            records.push(record)
          }
          if (v.endHospitalizationFlg) {
            searchInterval[1] = v.date
            intervals.push({
              startDate,
              startDateTime,
              searchInterval,
              endDate: v.date,
              endDateTime: v.date + v.endTime,
              records: v.startHospitalizationFlg
                ? [record]
                : [...records, record]
            })
            resetValues()
          }
        } else if (v.inHospitalFlg) {
          startDate = 'invalid'
          startDateTime = 'invalid'
          if (!searchInterval[0]) searchInterval[0] = v.date
          searchInterval[1] = v.date
          records.push(record)
        }
      } else {
        if (v.startHospitalizationFlg || v.endHospitalizationFlg) {
          if (v.startHospitalizationFlg) {
            intervals.push({
              startDate,
              startDateTime,
              searchInterval,
              endDate: 'invalid',
              endDateTime: 'invalid',
              records
            })
            resetValues()
            startDate = v.date
            startDateTime = v.date + v.startTime
            searchInterval = [v.date, v.date]
            records.push(record)
          }
          if (v.endHospitalizationFlg) {
            searchInterval[1] = v.date
            intervals.push({
              startDate,
              startDateTime,
              searchInterval,
              endDate: v.date,
              endDateTime: v.date + v.endTime,
              records: v.startHospitalizationFlg
                ? [record]
                : [...records, record]
            })
            resetValues()
          }
        } else if (v.inHospitalFlg) {
          if (!searchInterval[0]) searchInterval[0] = v.date
          searchInterval[1] = v.date
          records.push(record)
        } else {
          // pass
        }
      }
    })
  if (startDate) {
    intervals.push({
      startDate,
      startDateTime,
      searchInterval,
      endDate: '',
      endDateTime: '',
      records
    })
    resetValues()
  }
  return intervals
}
