<template>
  <div class="receipts-setting">
    <div class="wrap">
      <div class="title">レセプト</div>
      <div class="tabs">
        <div
          class="unsent tab"
          :class="{ active: tab === 'unsent' }"
          @click="toggleTab('unsent')"
          data-test="unsent"
        >
          アニコム未送信
        </div>
        <div
          class="sent tab"
          :class="{ active: tab === 'sent' }"
          @click="toggleTab('sent')"
          data-test="sent"
        >
          アニコム送信済
        </div>
        <div
          class="print tab"
          :class="{ active: tab === 'print' }"
          @click="toggleTab('print')"
          data-test="print"
        >
          診療明細書・アイペット請求書印刷
        </div>
      </div>
      <anicom-reports v-if="tab === 'unsent' || tab === 'sent'" :tab="tab" />
      <applied-medical-payments-print v-if="tab === 'print'" />
    </div>
  </div>
</template>

<script>
import AnicomReports from '@/components/parts/organisms/AnicomReports'
import AppliedMedicalPaymentsPrint from '@/components/parts/organisms/AppliedMedicalPaymentsPrint'

export default {
  name: 'ReceiptsSetting',

  components: {
    AnicomReports,
    AppliedMedicalPaymentsPrint
  },

  data() {
    return {
      tab: 'unsent'
    }
  },

  methods: {
    toggleTab(type) {
      this.tab = type
    }
  }
}
</script>

<style lang="scss" scoped>
.receipts-setting {
  width: 100%;
  overflow: auto;
  > .wrap {
    min-width: 1080px;
    padding: 24px 48px;
    text-align: left;
    > .title {
      font-weight: bold;
      font-size: 20px;
    }
    > .tabs {
      display: flex;
      height: 26px;
      margin-top: 20px;
      border-bottom: 1px solid;
      border-color: #{$light-grey};
      box-sizing: border-box;
      > .tab {
        box-sizing: border-box;
        min-width: 140px;
        font-size: 15px;
        text-align: center;
        line-height: 15px;
        padding: 0 20px;
        margin-bottom: -1px;
        cursor: pointer;
        &.active {
          border-bottom: 4px solid;
          border-color: #{$pumpkin};
          color: #{$pumpkin};
        }
      }
    }
  }
}
</style>
