<template>
  <div class="applied-medical-payments-print">
    <base-loading :waitFlg="waitFlg" />
    <div class="area print">
      <base-button-border-orange
        class="button"
        :styles="{ width: '140px' }"
        :disabled="checkedSearchedMedicalPayments.length === 0 || waitFlg"
        @click="isPrintOptionsPopupOpened = true"
        >診療明細書一括印刷</base-button-border-orange
      >
      <base-button-border-orange
        class="button"
        data-test="button insurance-claim"
        :disabled="!existsCheckedIpetMedicalPayments || waitFlg"
        @click="printInsuranceClaimForm"
        >アイペット保険金請求書印刷</base-button-border-orange
      >
    </div>
    <div class="area search">
      <search-area-detail
        v-if="detailSearchFlg"
        :selectBoxData="detailSelectBoxData"
        :searchConditions="searchConditions"
        :searchButtonFlg="true"
        :waitFlg="waitFlg"
        @hide-detail="toggleDetailSearchFlg"
        @select="setSelectId"
        @input-text="inputText($event)"
        @add-search-condition="addSearchCondition"
        @trash="deleteSearchCondition"
        @search="getData"
      />
      <search-area
        v-else
        :ref="'searchArea'"
        :textBoxLabel="'検索単語'"
        :placeholder="'飼主ID、飼主名、患者ID、患者名'"
        :textValue="searchText"
        :periodFlg="true"
        :periodLabel="'診療日'"
        :periodClearFlg="false"
        :defaultStartDate="defaultStartDate"
        :defaultEndDate="defaultEndDate"
        :toggleDetailSearchFlg="true"
        :searchButtonFlg="true"
        :waitFlg="waitFlg"
        @input="inputSearchText"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @show-detail="toggleDetailSearchFlg"
        @search="getData"
      />
    </div>
    <div class="area filter-order">
      <div class="result">検索結果：{{ searchedMedicalPayments.length }}件</div>
      <div class="setting">
        <div class="label">絞り込み</div>
        <base-select-box
          class="select-box"
          :selectData="insuranceTypes"
          v-model="insuranceType"
        />
        <div class="label">並び替え：診療日</div>
        <base-select-box
          class="select-box"
          :selectData="orderTypes"
          v-model="orderType"
        />
      </div>
    </div>
    <div class="area list">
      <applied-medical-payment-list-table
        :data="searchedMedicalPayments"
        :allCheckFlg="allCheckFlg"
        @toggle-all-check-flg="value => (allCheckFlg = value)"
        @input="
          obj => (searchedMedicalPayments[obj.index].isChecked = obj.value)
        "
      />
    </div>
    <announce-popup
      v-if="popup.alertFlg"
      :type="popup.type"
      :title="popup.title"
      :buttons="['閉じる']"
      @close="closePopup"
      >{{ popup.message }}</announce-popup
    >
    <print-options-popup-for-medical-payments
      v-if="isPrintOptionsPopupOpened"
      :checkedMedicalPayments="checkedSearchedMedicalPayments"
      :hospitalizations="resHospitalizations"
      :medicalRecords="resMedicalRecords"
      :medicalTreatmentItems="resMedicalTreatmentItems"
      :medicalPayments="resMedicalPayments"
      @close="isPrintOptionsPopupOpened = false"
    />
  </div>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import SearchAreaDetail from '@/components/parts/molecules/SearchAreaDetail'
import SearchArea from '@/components/parts/molecules/SearchArea'
import AppliedMedicalPaymentListTable from '@/components/parts/organisms/AppliedMedicalPaymentListTable'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import moment from 'moment'
import printOptionsPopupForMedicalPayments from '@/components/popups/printOptionsPopupForMedicalPayments'
import { PDFDocument } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import { drawTextPage } from '@/utils/insurance_claim_form'
import axios from 'axios'
import { isNumber, toHanKakuId } from '@/utils/convert_string'

export default {
  name: 'AppliedMedicalPaymentsPrint',

  components: {
    BaseLoading,
    SearchAreaDetail,
    SearchArea,
    AppliedMedicalPaymentListTable,
    BaseSelectBox,
    BaseButtonBorderOrange,
    AnnouncePopup,
    printOptionsPopupForMedicalPayments
  },

  data() {
    return {
      waitFlg: false,
      searchedMedicalPayments: [],
      detailSearchFlg: false,
      detailSelectBoxData: [
        { id: 1, name: '診療日', type: 'datePicker' },
        { id: 2, name: '飼主ID', type: 'textBox' },
        { id: 3, name: '飼主名', type: 'textBox' },
        { id: 4, name: '患者ID', type: 'textBox' },
        { id: 5, name: '患者名', type: 'textBox' },
        { id: 6, name: '保険割合', type: 'selectBox' }
      ],
      pledgeRateSelectData: [
        //↓searchConditionsのtextに選択した保険割合の数値(70,50,30)がセットされるように
        // したいため、idに保険割合の数値を設定しています
        { id: 70, name: '70%' },
        { id: 50, name: '50%' },
        { id: 30, name: '30%' }
      ],
      searchConditions: [{ id: 1, selectId: 0, text: '' }],
      searchText: '',
      startDate: moment()
        .startOf('months')
        .format('YYYYMMDD'),
      endDate: moment()
        .endOf('months')
        .format('YYYYMMDD'),
      defaultStartDate: moment()
        .startOf('months')
        .toDate(),
      defaultEndDate: moment()
        .endOf('months')
        .toDate(),
      insuranceType: 0,
      insuranceTypes: [
        { id: 0, name: 'アイペットのみ(ドコモのペット保険トータルケア含む)' },
        { id: 1, name: 'アニコムのみ' },
        { id: 2, name: '全て' }
      ],
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      allCheckFlg: false,
      popup: {
        alertFlg: false,
        type: '',
        title: '',
        message: '',
        resolve: null
      },
      resHospitalizations: [],
      resMedicalPayments: [],
      resMedicalRecords: [],
      resMedicalTreatmentItems: [],
      isPrintOptionsPopupOpened: false
    }
  },

  computed: {
    ...mapGetters({
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getSpecies: 'species/getDataById'
    }),
    checkedSearchedMedicalPayments() {
      return this.searchedMedicalPayments.filter(mp => mp.isChecked)
    },
    existsCheckedIpetMedicalPayments() {
      return this.checkedSearchedMedicalPayments.some(mp => mp.ipetCheckId > 0)
    }
  },

  watch: {
    allCheckFlg: function() {
      this.searchedMedicalPayments = this.allCheckFlg
        ? this.searchedMedicalPayments.map(mp => ({ ...mp, isChecked: true }))
        : this.searchedMedicalPayments.map(mp => ({ ...mp, isChecked: false }))
    },
    insuranceType: async function() {
      await this.getData()
    },
    orderType: async function() {
      await this.getData()
    }
  },

  async created() {
    await this.getData()
  },

  methods: {
    async getData() {
      this.waitFlg = true
      const trimSearchText = this.searchText.toLowerCase().replace(/\s+/g, '')
      const trimSearchConditions = this.searchConditions.map(v => {
        return {
          ...v,
          text: isNumber(v.text)
            ? v.text
            : v.selectId === 2 || v.selectId === 4
            ? toHanKakuId(v.text.toLowerCase().replace(/\s+/g, ''))
            : v.text.toLowerCase().replace(/\s+/g, '')
        }
      })
      const res = await this.$store.dispatch(
        'medicalPayments/searchAppliedData',
        {
          detailSearchFlg: this.detailSearchFlg,
          trimSearchText,
          period: { startDate: this.startDate, endDate: this.endDate },
          trimSearchConditions: trimSearchConditions,
          insuranceType: this.insuranceType,
          order: this.orderType === 0 ? 'DESC' : 'ASC'
        }
      )
      if (res.result === true) {
        this.searchedMedicalPayments = this.makeDisplayMedicalPayments(
          res.sortedHitDisplayMedicalPayments
        )
        this.allCheckFlg = false
        // storeのstateにセットするとsocket通信で最新の情報に更新されてしまうので、検索時点の情報を印刷機能でフロント側で使いたい場合はこちらを使用する
        this.resMedicalPayments = res.medicalPayments
        this.resMedicalRecords = res.medicalRecords
        this.resMedicalTreatmentItems = res.medicalTreatmentItems
        this.resHospitalizations = res.hospitalizations
      } else {
        this.popup = {
          alertFlg: true,
          type: 'failure',
          title: '失敗',
          message: '通信エラーが発生しました。'
        }
      }
      this.waitFlg = false
    },
    makeDisplayMedicalPayments(sortedHitDisplayMedicalPayments) {
      return sortedHitDisplayMedicalPayments.map(mp => {
        const patient = this.getPatient(mp.patientId)
        const owner = this.getOwner(patient.ownerId)
        //mpにjpInsurance(保険種類),policyNumber(証券番号),
        //medicalTreatmentDay(診療日),type(会計状態)の情報が入っています(バックエンドで加えた)
        //typeはリストでは使用していない。診療明細書一括印刷、アイペット保険金請求書印刷でも使用しないのであれば削除して良い
        return {
          ...mp,
          formatDate: moment(mp.medicalTreatmentDay, 'YYYYMMDD').format(
            'Y年M月D日（dd）'
          ),
          ownerShowId: owner.showId,
          ownerFullName: owner.lastName + owner.firstName,
          patientShowId: patient.showId,
          patientName: patient.name,
          speciesName: this.getSpecies(patient.speciesId).name,
          displayPledgeRate: mp.pledgeRate + '%',
          displayTaxIncludedPrice: '￥' + mp.taxIncludedPrice.toLocaleString(),
          displayInsurancePrice: '￥' + mp.insurancePrice.toLocaleString(),
          isChecked: false,
          ownerName: `${owner.lastName} ${owner.firstName}`,
          yearMonth: moment(mp.medicalTreatmentDay, 'YYYYMMDD').format('YYYYMM')
        }
      })
    },
    inputSearchText(text) {
      this.searchText = text
    },
    inputDate(date, dateType) {
      if (dateType === 'start') {
        this.startDate = date
        if (date > this.endDate) {
          this.endDate = date
          this.$refs.searchArea.endDate = moment(date).toDate()
        } else {
          const maxEndDate = moment(date, 'YYYYMMDD')
            .add(1, 'months')
            .format('YYYYMMDD')
          if (this.endDate > maxEndDate) {
            this.endDate = maxEndDate
            this.$refs.searchArea.endDate = moment(maxEndDate).toDate()
          }
        }
      } else {
        this.endDate = date
        if (date < this.startDate) {
          this.startDate = date
          this.$refs.searchArea.startDate = moment(date).toDate()
        } else {
          const minStartDate = moment(date, 'YYYYMMDD')
            .add(-1, 'months')
            .format('YYYYMMDD')
          if (this.startDate < minStartDate) {
            this.startDate = minStartDate
            this.$refs.searchArea.startDate = moment(minStartDate).toDate()
          }
        }
      }
    },
    toggleDetailSearchFlg() {
      this.searchText = ''
      const startDate = moment()
        .startOf('months')
        .format('YYYYMMDD')
      const endDate = moment()
        .endOf('months')
        .format('YYYYMMDD')
      this.searchConditions = [
        {
          id: 1,
          selectId: 1,
          text: `${startDate},${endDate}`,
          isSelectIdBoxDisabled: true
        }
      ]
      this.detailSearchFlg = !this.detailSearchFlg
    },
    setSelectId(idIndex) {
      const targetSearchCondition = this.searchConditions[idIndex.index]
      targetSearchCondition.selectId = idIndex.id
      if (idIndex.id === 6) {
        targetSearchCondition.text = 70
        targetSearchCondition.selectData = this.pledgeRateSelectData
      } else {
        targetSearchCondition.text = ''
        delete targetSearchCondition.selectData
      }
      this.$set(this.searchConditions, idIndex.index, targetSearchCondition)
    },
    makePeriodLimitedToWithinOneMonth(textObj) {
      const splitText = textObj.text.split(',')
      const startDate = splitText[0]
      const endDate = splitText[1]
      const maxEndDate = moment(startDate, 'YYYYMMDD')
        .add(1, 'months')
        .format('YYYYMMDD')
      return endDate > maxEndDate
        ? `${startDate},${maxEndDate}`
        : `${startDate},${endDate}`
    },
    inputText(textObj) {
      const targetSearchCondition = this.searchConditions[textObj.index]
      targetSearchCondition.text =
        textObj.type === 'datePicker'
          ? this.makePeriodLimitedToWithinOneMonth(textObj)
          : textObj.text
      this.$set(this.searchConditions, textObj.index, targetSearchCondition)
    },
    addSearchCondition() {
      const id = this.searchConditions[this.searchConditions.length - 1].id + 1
      this.searchConditions.push({ id, selectId: 0, text: '' })
    },
    deleteSearchCondition(id) {
      this.searchConditions = this.searchConditions.filter(v => v.id !== id)
    },
    async printInsuranceClaimForm() {
      // アニコムをチェックしていたら警告表示
      await this.checkExistsWarning()

      // pdf 処理の準備
      const pdfBytes = await axios
        .get('insurance_claim_form.pdf', { responseType: 'arraybuffer' })
        .then(res => res.data)
      const baseDoc = await PDFDocument.load(pdfBytes)
      const pdfDoc = await PDFDocument.load(pdfBytes)
      pdfDoc.registerFontkit(fontkit)
      const fontBytes = await axios
        .get('NotoSansMonoCJKJPRegular.otf', {
          responseType: 'arraybuffer'
        })
        .then(res => res.data)
      const font = await pdfDoc.embedFont(fontBytes)

      // pdf への書込み
      const claimPages = this.toClaimPages()
      const clinic = this.$store.getters['clinic/getData']
      const adminStaff = this.$store.getters['staffs/getStaffs'].find(
        v => v.adminFlg === 1
      )
      for (let i = 0; i < claimPages.length; i++) {
        const [basePage] = await pdfDoc.copyPages(baseDoc, [0])
        if (i !== 0) pdfDoc.addPage(basePage)
        const claimPage = claimPages[i]
        const pdfPage = pdfDoc.getPages()[i]
        drawTextPage(font, pdfPage, clinic, adminStaff, claimPage)
      }

      // 新規タブで pdf を開く
      pdfDoc.setTitle('保険金請求書')
      const savedPdfBytes = await pdfDoc.save()
      const blob = new Blob([savedPdfBytes], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.setAttribute('id', 'pdf-link')
      link.href = window.URL.createObjectURL(blob)
      window.open(link)
    },
    async checkExistsWarning() {
      const existsCheckedAnicom = this.checkedSearchedMedicalPayments.some(
        mp => mp.anicomCIdCheckId > 0
      )
      if (existsCheckedAnicom) {
        const message =
          'アイペット保険金請求書に記載されるのは保険種類がアイペットまたはドコモであるカルテのみです。'
        await this.openWarningPopup(message)
      }
    },
    toClaimPages() {
      const claimPages = []
      let tPageIndex = 0
      let nPageIndex = 0
      let tsPageIndex = 0
      let nsPageIndex = 0
      const claimsByYearMonth = this.toClaimsByYearMonth()
      const claimsBySortedYearMonth = Object.entries(
        claimsByYearMonth
      ).sort((a, b) =>
        this.orderType === 0 ? (b[0] < a[0] ? -1 : 1) : b[0] < a[0] ? 1 : -1
      )
      for (const [yearMonth, claims] of claimsBySortedYearMonth) {
        claims.forEach(claim => {
          if (claim.procedure === 'T') {
            if (!claimPages[tPageIndex]) {
              claimPages[tPageIndex] = {
                yearMonth,
                T: [],
                N: [],
                TS: [],
                NS: []
              }
            }
            claimPages[tPageIndex]['T'].push(claim)
            if (claimPages[tPageIndex]['T'].length === 8) {
              tPageIndex++
            }
          }
          if (claim.procedure === 'N') {
            if (!claimPages[nPageIndex]) {
              claimPages[nPageIndex] = {
                yearMonth,
                T: [],
                N: [],
                TS: [],
                NS: []
              }
            }
            claimPages[nPageIndex]['N'].push(claim)
            if (claimPages[nPageIndex]['N'].length === 3) {
              nPageIndex++
            }
          }
          if (claim.procedure === 'TS') {
            if (!claimPages[tsPageIndex]) {
              claimPages[tsPageIndex] = {
                yearMonth,
                T: [],
                N: [],
                TS: [],
                NS: []
              }
            }
            claimPages[tsPageIndex]['TS'].push(claim)
            if (claimPages[tsPageIndex]['TS'].length === 2) {
              tsPageIndex++
            }
          }
          if (claim.procedure === 'NS') {
            if (!claimPages[nsPageIndex]) {
              claimPages[nsPageIndex] = {
                yearMonth,
                T: [],
                N: [],
                TS: [],
                NS: []
              }
            }
            claimPages[nsPageIndex]['NS'].push(claim)
            if (claimPages[nsPageIndex]['NS'].length === 2) {
              nsPageIndex++
            }
          }
        })
        const nextPageIndex = claimPages.length
        tPageIndex = nextPageIndex
        nPageIndex = nextPageIndex
        tsPageIndex = nextPageIndex
        nsPageIndex = nextPageIndex
      }
      return claimPages
    },
    toClaimsByYearMonth() {
      const claimsByYearMonth = {}
      this.checkedSearchedMedicalPayments.forEach(v => {
        if (v.ipetCheckId > 0) {
          const { yearMonth } = v
          const claim = this.toClaim(v)
          claimsByYearMonth[yearMonth]
            ? claimsByYearMonth[yearMonth].push(claim)
            : (claimsByYearMonth[yearMonth] = [claim])
        }
      })
      return claimsByYearMonth
    },
    toClaim(medicalPayment) {
      // medicalPayment -> checkedSearchedMedicalPayments レコード
      const {
        medicalTreatmentDay, // 診療日
        pledgeRate, // てん補割合
        policyNumber, // 証券番号
        ownerName, // 被保険者名
        patientName, // ペット名
        disease1Id, // 傷病名（症状名）1
        disease2Id, // 傷病名（症状名）2
        taxIncludedPrice, // 診療費（税込）
        insurancePrice // 保険請求額
      } = medicalPayment
      // 通院
      const tDate = medicalTreatmentDay
      const tMonth = moment(tDate, 'YYYYMMDD').format('M')
      const tDay = moment(tDate, 'YYYYMMDD').format('D')
      // 入院 手術 発症日 ※入院の有無で値が変わる
      let nStartMonth = ''
      let nStartDay = ''
      let nEndMonth = ''
      let nEndDay = ''
      let sDate = ''
      let sMonth = ''
      let sDay = ''
      let onsetDate = ''
      if (medicalPayment.endHospitalizationFlg === 1) {
        // 入院明細の場合
        const hospitalizations =
          this.$store.getters['hospitalizations/getDataByPatientId'](
            medicalPayment.patientId
          ) ?? []
        const hospitalization = hospitalizations.find(v =>
          v.medicalPaymentOriginalIds.includes(medicalPayment.originalId)
        )
        const {
          startDate,
          endDate,
          medicalPaymentOriginalIds
        } = hospitalization
        // 入院日
        nStartMonth = moment(startDate, 'YYYYMMDD').format('M')
        nStartDay = moment(startDate, 'YYYYMMDD').format('D')
        nEndMonth = moment(endDate, 'YYYYMMDD').format('M')
        nEndDay = moment(endDate, 'YYYYMMDD').format('D')
        // 手術日
        medicalPaymentOriginalIds.forEach(originalId => {
          const medicalPayment = this.$store.getters[
            'medicalPayments/getDataByOriginalId'
          ](originalId)
          if (medicalPayment.surgeryFlg === 1) {
            const medicalRecord = this.$store.getters[
              'medicalRecords/getDataByOriginalId'
            ](medicalPayment.medicalRecordOriginalId)
            sDate = sDate
              ? medicalRecord.date < sDate
                ? medicalRecord.date
                : sDate
              : medicalRecord.date
          }
        })
        // 発症日
        onsetDate = medicalPayment.uncertainOnsetFlg
          ? startDate
          : medicalPayment.onsetDate
      } else {
        // 通院明細の場合
        sDate = medicalPayment.surgeryFlg === 1 ? medicalTreatmentDay : ''
        onsetDate = medicalPayment.uncertainOnsetFlg
          ? medicalTreatmentDay
          : medicalPayment.onsetDate
      }
      if (sDate) {
        sMonth = moment(sDate, 'YYYYMMDD').format('M')
        sDay = moment(sDate, 'YYYYMMDD').format('D')
      }
      // 傷病名（症状名）
      const disease1Name =
        this.$store.getters['diseases/getDataById'](disease1Id)?.name ?? ''
      const disease2Name =
        this.$store.getters['diseases/getDataById'](disease2Id)?.name ?? ''
      // 発症日
      const onsetYear = moment(onsetDate, 'YYYYMMDD').format('Y')
      const onsetMonth = moment(onsetDate, 'YYYYMMDD').format('M')
      const onsetDay = moment(onsetDate, 'YYYYMMDD').format('D')
      // 診療区分 procedure -> T：通院, N：入院, TS：通院手術, NS：入院手術
      const isN = medicalPayment.endHospitalizationFlg === 1
      const isS = sDate
      const procedure = isN && isS ? 'NS' : isN ? 'N' : isS ? 'TS' : 'T'
      // 診療費（税込）
      const localeTaxIncludedPrice = taxIncludedPrice.toLocaleString()
      // 保険請求額
      const localeInsurancePrice = insurancePrice.toLocaleString()

      const claim = {
        procedure, // 診療区分 ※テキストではなく区分分けに使用
        insurancePrice, // ※アイペットへの請求額合計で使用
        tMonth, // 通院 > 月
        tDay, // 通院 > 日
        nStartMonth, // 入院開始 > 月
        nStartDay, // 入院開始 > 日
        nEndMonth, // 入院終了 > 月
        nEndDay, // 入院終了 > 日
        sMonth, // 手術 > 月
        sDay, // 手術 > 日
        pledgeRate: String(pledgeRate), // てん補割合
        policyNumber, // 証券番号
        ownerName, // 被保険者名
        patientName, // ペット名
        disease1Name, // 傷病名（症状名）1
        disease2Name, // 傷病名（症状名）2
        onsetYear, // 発症日 > 年
        onsetMonth, // 発症日 > 月
        onsetDay, // 発症日 > 日
        localeTaxIncludedPrice, // 診療費（税込）
        localeInsurancePrice // 保険請求額
      }
      return claim
    },
    async openWarningPopup(message) {
      this.popup = { alertFlg: true, type: 'alert', title: '注意', message }
      await new Promise(resolve => (this.popup.resolve = resolve))
    },
    closePopup() {
      if (this.popup.resolve) this.popup.resolve()
      this.popup = {
        alertFlg: false,
        type: '',
        title: '',
        message: '',
        resolve: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.applied-medical-payments-print {
  > .print {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 0 20px;
  }
  > .search {
    margin-top: 20px;
  }
  > .filter-order {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    height: 33px;
    > .result {
      margin-left: 10px;
    }
    > .setting {
      display: flex;
      align-items: center;
      gap: 0 10px;
    }
  }
  > .list {
    margin-top: 15px;
  }
}
</style>
