export const counterPaymentNg = [
  {
    kekka1: '正しい対応医療機関コードを入力してください。',
    kekka2:
      '入力されたパスワードと一致しません。対応医療機関コードをお確かめの上、再度ご入力ください。'
  },
  {
    kekka1: '現在対応医療機関登録作業中です。',
    kekka2:
      '誠に恐縮ですが、窓口での精算をすることができません。被保険者より直接アニコムに保険金をご請求いただくようお伝えください。'
  },
  {
    kekka1: '対応医療機関ではありません。',
    kekka2:
      '誠に恐縮ですが、窓口での精算をすることができません。被保険者より直接アニコムに保険金をご請求いただくようお伝えください。'
  },
  {
    kekka1: '対応医療機関取扱停止の申請をいただきました。',
    kekka2:
      '誠に恐縮ですが、窓口での精算をすることができません。被保険者より直接アニコムに保険金をご請求いただくようお伝えください。'
  },
  {
    kekka1: '入力内容が不正です。',
    kekka2: '証券番号は必須入力です。どうぶつ生年月日は必須入力です。'
  },
  {
    kekka1: '入力情報と一致する契約データが確認できません。',
    kekka2: '証券番号及びどうぶつ生年月日をご確認ください。'
  },
  {
    kekka1: '100％補償期間の為、窓口での精算をご利用いただけません。',
    kekka2:
      '100％補償期間の為、窓口での精算ができません。被保険者より直接アニコムに保険金をご請求いただくようお伝えください。'
  },
  {
    kekka1: '待機期間中の為、窓口での精算をご利用いただけません。',
    kekka2:
      '待機期間中の為、窓口での精算をご利用いただけません。ただし、診療目的が傷害の場合は保険期間の初日以降保険の対象となりますので、被保険者より直接アニコムに保険金をご請求いただくようお伝えください。'
  },
  {
    kekka1: '窓口での精算をご利用いただけません。',
    kekka2:
      '窓口での精算をご利用いただけませんので被保険者より直接アニコムに保険金をご請求いただくようお伝えください。窓口での精算をご利用いただけない理由については被保険者よりアニコムにお問合せいただくようお伝えください。'
  },
  {
    kekka1: '本日は既に窓口での精算を行っています。',
    kekka2:
      '本日は貴院にて既に承認番号を取得済みです。詳細は以下よりご確認ください。●-●●●●'
  },
  {
    kekka1: '本日は既に窓口での精算を行っています。',
    kekka2:
      '同日に2回以上の診療を受けられる場合には、被保険者より直接アニコムに保険金をご請求いただくようお伝えください。'
  },
  {
    kekka1: 'ご契約始期前の為、窓口での精算をご利用いただけません。',
    kekka2:
      '補償は開始されておりません。どうぶつ健康保険証の保険期間をご確認ください。'
  },
  {
    kekka1: 'ご契約満期後の為、窓口での精算をご利用いただけません。',
    kekka2:
      '新しい証券番号のお知らせが、被保険者のお手元に届いていないかご確認ください。ご契約内容の詳細は、個人情報に該当しますので被保険者よりアニコムにお問い合わせいただくようお伝えください。'
  },
  {
    kekka1: 'この証券番号のご契約は有効ではありません。',
    kekka2:
      'ご契約内容の詳細は、個人情報に該当しますので被保険者よりアニコムにお問い合わせいただくようお伝えください。'
  },
  {
    kekka1: '窓口精算はできません。',
    kekka2: '通院、入院ともに限度日数に達しました。窓口精算はできません。'
  },
  {
    kekka1: '窓口精算はできません。',
    kekka2:
      '通院、入院ともに限度日数に達しました。窓口精算はできません。 手術枠の限度に達していますので、限度額にご注意ください。'
  }
]

export const counterPaymentOk = [
  {
    kekka1: '50%で窓口精算可能です。70%で窓口精算可能です。',
    kekka2: '通院・入院ともに窓口精算が可能です。'
  },
  {
    kekka1: '50%で窓口精算可能です。70%で窓口精算可能です。',
    kekka2:
      '通院・入院ともに窓口精算が可能です。手術枠の限度に達していますので、限度額にご注意ください。'
  }
]

export const counterPaymentOkOutpatient = [
  {
    kekka1: '50%で通院のみ窓口精算可能です。70%で通院のみ窓口精算可能です。',
    kekka2: '通院の場合のみ窓口精算が可能です。入院の窓口精算はできません。'
  },
  {
    kekka1: '50%で通院のみ窓口精算可能です。70%で通院のみ窓口精算可能です。',
    kekka2:
      '通院の場合のみ窓口精算が可能です。入院の窓口精算はできません。手術枠の限度に達していますので、限度額にご注意ください。'
  }
]

export const counterPaymentOkInpatient = [
  {
    kekka1: '50%で入院のみ窓口精算可能です。70%で入院のみ窓口精算可能です。',
    kekka2: '通院の窓口精算はできません。入院の場合のみ窓口精算が可能です。'
  },
  {
    kekka1: '50%で入院のみ窓口精算可能です。70%で入院のみ窓口精算可能です。',
    kekka2:
      '通院の窓口精算はできません。入院の場合のみ窓口精算が可能です。手術枠の限度に達していますので、限度額にご注意ください。'
  }
]

export const surgeryNg = [
  '手術枠はご利用いただけません。限度額にご注意ください。'
]

export const makeAnicomRezeptResponse = ngList => {
  let errorMessage = ''
  if (ngList.includes('ol_rece_key')) {
    errorMessage += 'オンライン請求キーに不備があります。\n'
  }
  if (ngList.includes('c_id')) {
    errorMessage += '証券番号に不備があります。\n'
  }
  if (ngList.includes('rezept_cd')) {
    errorMessage += '承認番号に不備があります。\n'
  }
  if (ngList.includes('c_sname')) {
    errorMessage += '契約者姓に不備があります。\n'
  }
  if (ngList.includes('ani_name')) {
    errorMessage += '動物名に不備があります。\n'
  }
  if (ngList.includes('diagnosis_1')) {
    errorMessage += '診断名（大項目）に不備があります。\n'
  }
  if (ngList.includes('diagnosis_2')) {
    errorMessage += '診断名（小項目）に不備があります。\n'
  }
  if (ngList.includes('dic_date')) {
    errorMessage += '通院日に不備があります。\n'
  }
  if (ngList.includes('his_date')) {
    errorMessage += '入院日に不備があります。\n'
  }
  if (ngList.includes('hie_date')) {
    errorMessage += '退院日に不備があります。\n'
  }
  if (ngList.includes('op_date')) {
    errorMessage += '手術日に不備があります。\n'
  }
  if (ngList.includes('onset_date')) {
    errorMessage += '疾病の発症または傷害の受傷時期に不備があります。\n'
  }
  if (ngList.includes('dic_amount')) {
    errorMessage += '診療費に不備があります。\n'
  }
  if (ngList.includes('tgt_amount')) {
    errorMessage += '保険補償対象額に不備があります。\n'
  }
  if (ngList.includes('ins_amount')) {
    errorMessage += '保険金請求額に不備があります。\n'
  }
  if (ngList.includes('pledge_rate')) {
    errorMessage += '支払い割合に不備があります。\n'
  }
  if (ngList.includes('reason')) {
    errorMessage += '補償対象外理由に不備があります。\n'
  }
  if (ngList.includes('h_cd')) {
    errorMessage += '医療機関コードに不備があります。\n'
  }
  if (ngList.includes('system_ver')) {
    errorMessage += 'システム名＆システムバージョンに不備があります。\n'
  }
  if (ngList.includes('rece_o_id')) {
    errorMessage += 'レセプトIDに不備があります。\n'
  }
  if (ngList.includes('受付済')) {
    errorMessage += '既に受付済みです。\n'
  }
  return errorMessage
}
