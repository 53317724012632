import * as self from '@/utils/insurance_claim_form'
import moment from 'moment'

// A4横サイズ ※横幅 841.89
const pageHeight = 595.28 // 縦幅

// 医院欄の座標等の情報
export const clinicPositions = {
  vhCode: {
    startX: 44.9, // テキスト枠の左端の絶対座標
    endX: 152.66, // テキスト枠の右端の絶対座標
    startY: 78.6, // テキスト枠の上端の絶対座標
    indent: 8.42, // 枠内での先頭文字位置
    bottom: 21.6 // 枠内でのテキスト底部位置
  },
  clinicName: {
    startX: 163.64,
    endX: 359.82,
    startY: 78.6,
    indent: 3,
    bottom: 22
  },
  clinicTel: {
    startX: 370.78,
    endX: 483.66,
    startY: 78.6,
    indent: 3,
    bottom: 22
  },
  staffName: {
    startX: 494.62,
    endX: 602.78,
    startY: 78.6,
    indent: 3,
    bottom: 22
  }
}

export const yearMonthPositions = {
  year: {
    startX: 658,
    bottomY: 101.5 // テキスト底部の絶対座標
  },
  month: {
    startX: 750,
    bottomY: 101.5
  }
}

// 請求書欄の線幅
const lineObj = {
  in: 0.6,
  out: 2.3
}

// 列 始点
export const columnStartXs = {
  1: 28.58,
  2: 144.44,
  3: 199.51,
  4: 286.71,
  5: 373.14,
  6: 442.93,
  7: 558.18,
  8: 644.17,
  9: 729.71,
  10: 815.6 // 右端
}

export const columnOrders = {
  tDate: 1,
  nDate: 1,
  sDate: 1,
  pledgeRate: 2,
  policyNumber: 3,
  ownerName: 4,
  patientName: 5,
  diseaseName: 6,
  disease1Name: 6,
  disease2Name: 6,
  onsetDate: 7,
  localeTaxIncludedPrice: 8,
  localeInsurancePrice: 9,
  end: 10 // 右端
}

// 行 始点 > 通院
const tStartY = 136.57
const tInterval = 22.34
export const tRowStartYs = {
  1: tStartY,
  2: tStartY + tInterval,
  3: tStartY + tInterval * 2,
  4: tStartY + tInterval * 3,
  5: tStartY + tInterval * 4,
  6: tStartY + tInterval * 5,
  7: tStartY + tInterval * 6,
  8: tStartY + tInterval * 7
}

// 行 始点 > 入院
const nStartY = 334.75
const nInterval = 22.67
export const nRowStartYs = {
  1: nStartY,
  2: nStartY + nInterval,
  3: nStartY + nInterval * 2
}

// 行 始点 > 手術を含む通院
const tsStartY = 420.47
const tsInterval = 24.33
export const tsRowStartYs = {
  1: tsStartY,
  2: tsStartY + tsInterval
}

// 行 始点 > 手術を含む入院
const nsStartY = 487.58
const nsInterval = 28.08
export const nsRowStartYs = {
  1: nsStartY,
  2: nsStartY + nsInterval
}

// テキスト底部位置 ※診療区分で異なる
export const textBottoms = {
  tDate: { T: 15 },
  nDate: { N: 14, NS: 10.6 },
  sDate: { TS: 15.4, NS: 23.6 },
  pledgeRate: { T: 16, N: 16.2, TS: 17, NS: 19 },
  policyNumber: { T: 16, N: 16.2, TS: 17, NS: 19 },
  ownerName: { T: 14.8, N: 15, TS: 15.8, NS: 17.8 },
  patientName: { T: 14.8, N: 15, TS: 15.8, NS: 17.8 },
  diseaseName: { T: 14.8, N: 15, TS: 15.8, NS: 17.8 },
  disease1Name: { T: 7.87, N: 8.04, TS: 9.17, NS: 10.74 },
  disease2Name: { T: 18.7, N: 19, TS: 20.7, NS: 24.5 },
  onsetDate: { T: 14.9, N: 14.8, TS: 15.3, NS: 18 },
  localeTaxIncludedPrice: { T: 16, N: 16.2, TS: 16.7, NS: 19 },
  localeInsurancePrice: { T: 16, N: 16.2, TS: 16.7, NS: 19 }
}

// テキスト幅
export const widthOfTextAtSize = (font, text, size) => {
  return font.widthOfTextAtSize(text, size)
}

// 列左端の x 座標
export const toColumnStartX = column => {
  const order = columnOrders[column]
  const startX = columnStartXs[order]
  return startX
}

// 列幅
export const toColumnWidth = column => {
  const order = columnOrders[column]
  const startX = columnStartXs[order]
  const nextStartX = columnStartXs[order + 1]
  const lineWidth = order === 9 ? lineObj['out'] : lineObj['in']
  const columnWidth = nextStartX - startX - lineWidth
  return columnWidth
}

// 左揃えの x 座標
export const toXinLeftAlign = (column, leftAlignX) => {
  const startX = toColumnStartX(column)
  const x = startX + leftAlignX
  return x
}

// 右揃えの x 座標
export const toXinRightAlign = (font, size, column, rightAlignX, text) => {
  const order = columnOrders[column]
  const startX = columnStartXs[order]
  const textWidth = self.widthOfTextAtSize(font, text, size)
  const x = startX - textWidth + rightAlignX
  return x
}

// 右揃えの x 座標
export const toXinRightAlign2 = (font, size, position, rightAlignX, text) => {
  const { startX } = position
  const textWidth = self.widthOfTextAtSize(font, text, size)
  const x = startX + rightAlignX - textWidth
  return x
}

// 右揃えの x 座標と調整したフォントサイズ
export const toSizeXinRightAlign = (
  font,
  startSize,
  column,
  textStartX,
  rightAlignX,
  text
) => {
  const textSpaceWidth = rightAlignX - textStartX
  let size = startSize
  let textWidth = self.widthOfTextAtSize(font, text, size)
  while (textWidth >= textSpaceWidth) {
    size -= 0.1
    textWidth = self.widthOfTextAtSize(font, text, size)
  }
  const startX = columnStartXs[columnOrders[column]]
  const x = startX - textWidth + rightAlignX
  return { size, x }
}

// 中央揃えの x 座標と調整したフォントサイズ
export const toSizeXinCenterAlign = (
  font,
  startSize,
  column,
  textStartX,
  text
) => {
  const columnWidth = toColumnWidth(column)
  const textSpaceWidth = columnWidth - textStartX * 2
  const { size, textWidth } = self.adjustSize(
    font,
    startSize,
    textSpaceWidth,
    text
  )
  const startX = columnStartXs[columnOrders[column]]
  const x = startX + textStartX + (textSpaceWidth - textWidth) / 2
  return { size, x }
}

// 中央揃えの x 座標と調整したフォントサイズ
export const toSizeXinCenterAlign2 = (font, startSize, position, text) => {
  const { startX, endX, indent } = position
  const frameWidth = endX - startX
  const textSpaceWidth = frameWidth - indent * 2
  const { size, textWidth } = self.adjustSize(
    font,
    startSize,
    textSpaceWidth,
    text
  )
  const x = startX + (frameWidth - textWidth) / 2
  return { size, x }
}

// テキスト領域に収まるようフォントサイズを調整
export const adjustSize = (font, startSize, textSpaceWidth, text) => {
  let size = startSize
  let textWidth = self.widthOfTextAtSize(font, text, size)
  while (textWidth >= textSpaceWidth) {
    size -= 0.1
    textWidth = self.widthOfTextAtSize(font, text, size)
  }
  return { size, textWidth }
}

// テキスト底部の y 座標
export const toY = (startY, column, procedure) => {
  const bottom = textBottoms[column][procedure]
  const y = self.adjustY(startY + bottom)
  return y
}

// y 座標を drawText 用に調整
export const adjustY = y => pageHeight - y

// ページテキストを描画
export const drawTextPage = (font, page, clinic, adminStaff, claimPage) => {
  self.drawTextClinic(font, page, clinic, adminStaff, claimPage.yearMonth)
  let insuranceAmount = 0
  const procedures = ['T', 'N', 'TS', 'NS']
  procedures.forEach(procedure => {
    const claimsByProcedure = claimPage[procedure]
    claimsByProcedure.forEach((claim, i) => {
      self.drawTextClaim(font, page, i + 1, claim)
      insuranceAmount += claim.insurancePrice
    })
  })
  const localeInsuranceAmount = insuranceAmount.toLocaleString()
  self.drawTextInsuranceAmount(font, page, localeInsuranceAmount)
}

// 医院情報 + 年月 の描画
export const drawTextClinic = (font, page, clinic, staff, yearMonth) => {
  const year = moment(yearMonth, 'YYYYMM').format('YY')
  const month = moment(yearMonth, 'YYYYMM').format('M')
  self.drawTextVhCode(font, page, clinic.vhCode)
  self.drawTextClinicName(font, page, clinic.name)
  self.drawTextClinicTel(font, page, clinic.tel)
  self.drawTextStaffName(font, page, staff.lastName)
  self.drawTextPageYear(font, page, year)
  self.drawTextPageMonth(font, page, month)
}
export const drawTextVhCode = (font, page, vhCode) => {
  const text = vhCode.substring(1)
  const position = clinicPositions['vhCode']
  const { startY, bottom } = position
  const { size, x } = self.toSizeXinCenterAlign2(font, 16, position, text)
  const y = self.adjustY(startY + bottom)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextClinicName = (font, page, text) => {
  const position = clinicPositions['clinicName']
  const { startY, bottom } = position
  const { size, x } = self.toSizeXinCenterAlign2(font, 16, position, text)
  const y = self.adjustY(startY + bottom)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextClinicTel = (font, page, text) => {
  const position = clinicPositions['clinicTel']
  const { startY, bottom } = position
  const { size, x } = self.toSizeXinCenterAlign2(font, 16, position, text)
  const y = self.adjustY(startY + bottom)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextStaffName = (font, page, text) => {
  const position = clinicPositions['staffName']
  const { startY, bottom } = position
  const { size, x } = self.toSizeXinCenterAlign2(font, 16, position, text)
  const y = self.adjustY(startY + bottom)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextPageYear = (font, page, text) => {
  const size = 19
  const position = yearMonthPositions['year']
  const { bottomY } = position
  const x = self.toXinRightAlign2(font, size, position, 23, text)
  const y = self.adjustY(bottomY)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextPageMonth = (font, page, text) => {
  const size = 19
  const position = yearMonthPositions['month']
  const { bottomY } = position
  const x = self.toXinRightAlign2(font, size, position, 23, text)
  const y = self.adjustY(bottomY)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// 請求内容の描画
export const drawTextClaim = (font, page, rowNo, claim) => {
  const {
    procedure,
    tMonth,
    tDay,
    nStartMonth,
    nStartDay,
    nEndMonth,
    nEndDay,
    sMonth,
    sDay,
    pledgeRate,
    policyNumber,
    ownerName,
    patientName,
    disease1Name,
    disease2Name,
    onsetYear,
    onsetMonth,
    onsetDay,
    localeTaxIncludedPrice,
    localeInsurancePrice
  } = claim

  // 区分毎の行始点 Y
  const startY = self.toStartY(procedure, rowNo)

  const baseArgs = [font, page, startY, procedure]
  // 通院
  self.drawTextTMonth(...baseArgs, tMonth)
  self.drawTextTDay(...baseArgs, tDay)
  // 入院
  self.drawTextNStartMonth(...baseArgs, nStartMonth)
  self.drawTextNStartDay(...baseArgs, nStartDay)
  self.drawTextNEndMonth(...baseArgs, nEndMonth)
  self.drawTextNEndDay(...baseArgs, nEndDay)
  // 手術
  self.drawTextSMonth(...baseArgs, sMonth)
  self.drawTextSDay(...baseArgs, sDay)
  // てん補割合
  self.drawTextPledgeRate(...baseArgs, pledgeRate)
  // 証券番号
  self.drawTextPolicyNumber(...baseArgs, policyNumber)
  // 被保険者名
  self.drawTextOwnerName(...baseArgs, ownerName)
  // ペット名
  self.drawTextPatientName(...baseArgs, patientName)
  // 傷病名（症状名）
  self.drawTextDiseaseName(...baseArgs, disease1Name, disease2Name)
  // 発症日 onsetDate
  self.drawTextOnsetYear(...baseArgs, onsetYear)
  self.drawTextOnsetMonth(...baseArgs, onsetMonth)
  self.drawTextOnsetDay(...baseArgs, onsetDay)
  // 診療費（税込）
  self.drawTextTaxIncludedPrice(...baseArgs, localeTaxIncludedPrice)
  // 保険金請求金額（税込）
  self.drawTextInsurancePrice(...baseArgs, localeInsurancePrice)
}

// 行始点
export const toStartY = (procedure, rowNo) => {
  const startY =
    procedure === 'NS'
      ? nsRowStartYs[rowNo]
      : procedure === 'N'
      ? nRowStartYs[rowNo]
      : procedure === 'TS'
      ? tsRowStartYs[rowNo]
      : tRowStartYs[rowNo]
  return startY
}

// 通院 > 月日
export const drawTextTMonth = (font, page, startY, procedure, text) => {
  const isT = procedure === 'T'
  if (!isT) return
  const column = 'tDate'
  const size = 12
  const x = self.toXinRightAlign(font, size, column, 68, text)
  const y = self.toY(startY, 'tDate', procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextTDay = (font, page, startY, procedure, text) => {
  const isT = procedure === 'T'
  if (!isT) return
  const column = 'tDate'
  const size = 12
  const x = self.toXinRightAlign(font, size, column, 98, text)
  const y = self.toY(startY, 'tDate', procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// 入院 > 入院開始月日 入院終了月日
export const drawTextNStartMonth = (font, page, startY, procedure, text) => {
  const isN = procedure === 'N' || procedure === 'NS'
  if (!isN) return
  const column = 'nDate'
  const size = 8
  const x = self.toXinRightAlign(font, size, column, 33, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextNStartDay = (font, page, startY, procedure, text) => {
  const isN = procedure === 'N' || procedure === 'NS'
  if (!isN) return
  const column = 'nDate'
  const size = 8
  const x = self.toXinRightAlign(font, size, column, 51.4, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextNEndMonth = (font, page, startY, procedure, text) => {
  const isN = procedure === 'N' || procedure === 'NS'
  if (!isN) return
  const column = 'nDate'
  const size = 8
  const x = self.toXinRightAlign(font, size, column, 83, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextNEndDay = (font, page, startY, procedure, text) => {
  const isN = procedure === 'N' || procedure === 'NS'
  if (!isN) return
  const column = 'nDate'
  const size = 8
  const x = self.toXinRightAlign(font, size, column, 101.4, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// 手術 > 月日
export const drawTextSMonth = (font, page, startY, procedure, text) => {
  const isS = procedure === 'TS' || procedure === 'NS'
  if (!isS) return
  const column = 'sDate'
  const size = procedure === 'NS' ? 8 : 12
  const rightAlignX = procedure === 'NS' ? 72 : 71
  const x = self.toXinRightAlign(font, size, column, rightAlignX, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextSDay = (font, page, startY, procedure, text) => {
  const isS = procedure === 'TS' || procedure === 'NS'
  if (!isS) return
  const column = 'sDate'
  const size = procedure === 'NS' ? 8 : 12
  const rightAlignX = procedure === 'NS' ? 100 : 99.8
  const x = self.toXinRightAlign(font, size, column, rightAlignX, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// てん補割合
export const drawTextPledgeRate = (font, page, startY, procedure, text) => {
  const column = 'pledgeRate'
  const size = 14
  const x = self.toXinRightAlign(font, size, column, 30, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// 証券番号
export const drawTextPolicyNumber = (font, page, startY, procedure, text) => {
  const column = 'policyNumber'
  const size = 14
  const x = self.toXinLeftAlign(column, 8)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// 被保険者名
export const drawTextOwnerName = (font, page, startY, procedure, text) => {
  const column = 'ownerName'
  const { size, x } = self.toSizeXinCenterAlign(font, 10, column, 3, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// ペット名
export const drawTextPatientName = (font, page, startY, procedure, text) => {
  const column = 'patientName'
  const { size, x } = self.toSizeXinCenterAlign(font, 10, column, 3, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// 傷病名（症状名）
export const drawTextDiseaseName = (
  font,
  page,
  startY,
  procedure,
  disease1Name,
  disease2Name
) => {
  if (disease1Name && disease2Name) {
    const args = [font, page, startY, procedure]
    self.drawTextDisease1(...args, disease1Name)
    self.drawTextDisease2(...args, disease2Name)
  } else if (disease1Name || disease2Name) {
    const text = disease1Name || disease2Name
    const column = 'diseaseName'
    const { size, x } = self.toSizeXinCenterAlign(font, 10, column, 3, text)
    const y = self.toY(startY, column, procedure)
    const options = { x, y, size, font }
    page.drawText(text, options)
  }
}
export const drawTextDisease1 = (font, page, startY, procedure, text) => {
  const column = 'disease1Name'
  const { size, x } = self.toSizeXinCenterAlign(font, 7, column, 3, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextDisease2 = (font, page, startY, procedure, text) => {
  const column = 'disease2Name'
  const { size, x } = self.toSizeXinCenterAlign(font, 7, column, 3, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// 発症日 > 年月日
export const drawTextOnsetYear = (font, page, startY, procedure, text) => {
  const column = 'onsetDate'
  const size = 10
  const x = self.toXinRightAlign(font, size, column, 24, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextOnsetMonth = (font, page, startY, procedure, text) => {
  const column = 'onsetDate'
  const size = 10
  const x = self.toXinRightAlign(font, size, column, 47, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}
export const drawTextOnsetDay = (font, page, startY, procedure, text) => {
  const column = 'onsetDate'
  const size = 10
  const x = self.toXinRightAlign(font, size, column, 71, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// 診療費（税込）
export const drawTextTaxIncludedPrice = (
  font,
  page,
  startY,
  procedure,
  text
) => {
  const column = 'localeTaxIncludedPrice'
  const { size, x } = self.toSizeXinRightAlign(font, 14, column, 3.4, 69, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// 保険金請求金額
export const drawTextInsurancePrice = (font, page, startY, procedure, text) => {
  const column = 'localeInsurancePrice'
  const { size, x } = self.toSizeXinRightAlign(font, 14, column, 3.4, 67, text)
  const y = self.toY(startY, column, procedure)
  const options = { x, y, size, font }
  page.drawText(text, options)
}

// アイペットへの請求額合計
export const drawTextInsuranceAmount = (font, page, text) => {
  const textStartX = 813.46 - 806.72 // 「円」と右側外枠との間の長さと同じ
  const rightAlignX = 155
  const textSpaceWidth = rightAlignX - textStartX
  const { size, textWidth } = self.adjustSize(font, 18, textSpaceWidth, text)
  const startX = 632
  const x = startX - textWidth + rightAlignX
  const options = { x, y: 30, size, font }
  page.drawText(text, options)
}
